* {
  /* outline: 1px solid red; */
}

head,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
